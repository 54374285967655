document.addEventListener("DOMContentLoaded", () => {
    const phoneInput = document.getElementById("fc-generated-1-phone");
  
    phoneInput.addEventListener("input", (event) => {
      let value = phoneInput.value.replace(/\D/g, ""); // Remove caracteres não numéricos
  
      // Limita o valor a no máximo 11 números
      if (value.length > 11) {
        value = value.slice(0, 11);
      }
  
      // Aplica a máscara ao valor digitado
      if (value.length > 2) {
        value = `(${value.slice(0, 2)}) ${value.slice(2)}`;
      }
      if (value.length > 7) {
        value = `${value.slice(0, 9)}-${value.slice(9)}`;
      }
  
      phoneInput.value = value; // Atualiza o campo com o valor formatado
    });
  });